import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_LANGUAGE,
  GET_LANGUAGES,
  SEARCH_LANGUAGE,
  STORE_LANGUAGE,
  UPDATE_LANGUAGE
} from "@/store/modules/language.module";

export default {
  computed: {
    ...mapGetters(['languages', 'languagesMeta', 'language', 'languageError', 'searchLanguageList', 'searchLanguageListMeta'])
  },
  methods: {
    getLanguage(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_LANGUAGE, id),
      actions
      );
    },
    getLanguages(page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('languageBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('languageBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_LANGUAGES, {page, title}),
        actions
      );
    },
    storeLanguage(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_LANGUAGE, formData),
        actions
      );
    },
    updateLanguage(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_LANGUAGE, formData),
      actions
      );
    },
    searchLanguage(title = '', page = 0, actions = {
      begin: ()=>{ this.$emit('search:begin'); },
      after: (response, state)=>{ this.$emit('search:end', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(SEARCH_LANGUAGE, {title, page}),
      actions
      );
    },
  }
}
