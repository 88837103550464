<template>
  <div class="film-form-container">
    <b-row>
      <b-col md="2" sm="6" v-if="action === 'create'">
        <b-form-group id="input-group-title" label="فىلىم تۈرى" label-for="content_model">
          <b-form-radio-group
            id="content_model"
            v-model="formData.content_model"
            :options="contentModel"
            @change="onChangeContentModel"
            buttons />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
          <b-form-checkbox id="is_show" switch size="lg" v-model="formData.is_show"  />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6" v-if="! storyMode && action === 'create'">
        <b-form-group id="input-group-is_series" label="كۆپ قىسىملىقمۇ ؟" label-for="is_series">
          <b-form-checkbox id="is_series" switch size="lg" @change="onChangeSeries" v-model="formData.is_series"/>
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-is_hidden" label="يۇشۇرۇپ قويامسىز ؟" label-for="is_hidden">
          <b-form-checkbox id="is_hidden" switch size="lg" v-model="formData.is_hidden"  />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-in_home" label="باشبەتتە كۆرۈنەمدۇ ؟" label-for="in_home">
          <b-form-checkbox id="in_home" switch size="lg" v-model="formData.in_home"  />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-is_recommend" label="تەۋسىيەلىكمۇ ؟" label-for="is_recommend">
          <b-form-checkbox id="is_recommend" switch size="lg" v-model="formData.is_recommend"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" sm="6">
        <b-form-group id="input-group-title" label="فىلىم ماۋزوسى" label-for="title">
          <b-form-input id="title" autofocus v-model="formData.title" lazy-formatter :formatter="handleTitle" type="text" :state="validateState('title')" aria-describedby="title-live-feedback" placeholder="فىلىم ماۋزوسىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="title-live-feedback">
            فىلىم ماۋزوسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6">
        <b-form-group id="input-group-sub_title" label="قىسقا ماۋزو" label-for="sub_title">
          <b-input-group :append-html="`<span dir='ltr' style='color: ${subTitleLength < 0 ? 'red' : ''}'> ${subTitleLength.toString()} </span>`">
            <b-form-input :state="validateState('sub_title')" valid-feedback=" " invalid-feedback="قىسقا ماۋزو ئۇزۇنلىقى 19 ھەرىپتىن ئېشىپ كەتمىسۇن" id="sub_title" v-model="formData.sub_title" placeholder="فىلىم قىسقا ماۋزوسىنى كىرگۈزۈڭ" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6">
        <b-form-group id="input-group-score" label="فىلىم دوۋبەن نۇمۇرى" label-for="score" >
          <b-form-spinbutton id="score" step="0.1" max="10.0" v-model="formData.score" ></b-form-spinbutton>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6" v-if="! storyMode">
        <b-form-group id="input-group-platform_ratio" label="سۇپا پايدا نىسپىتى" label-for="platform_ratio" >
          <b-input-group prepend="%">
            <b-form-spinbutton id="platform_ratio" step="1" max="100" min="0" v-model="formData.platform_ratio" ></b-form-spinbutton>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" sm="6">
        <b-form-group id="input-group-state_content" label="فىلىم ھالەت مەزمۇنى" label-for="state_content">
          <b-input-group :append="formData.state_content ? formData.state_content.length.toString() : '0'">
            <b-form-input id="state_content" max="25" v-model="formData.state_content" type="text" placeholder="فىلىم ھالەت مەزمونىنى كىرگۈزۈڭ، 25 ھەرىپتىن ئېشىپ كەتمىسۇن" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <!--<b-col md="3" sm="6">
        <b-form-group id="input-group-state_bg_class" label="ھالەت مەزمۇنى ئارقا رەڭ نۇمۇرى" label-for="state_bg_class">
          <b-input-group>
            <b-form-input id="state_bg_class" placeholder="فىلىم كارتىسىدا كۆرىنىدىغان ھالەت مەزمۇنى ئارقا رەڭگە ئىشلىتىدىغان رەڭ" style="direction: ltr" readonly v-model="formData.state_bg_class" />
            <b-input-group-append>
              <b-button class="p-0" @click="openClassSelector" :class="formData.state_bg_class">
                <bg-class-selector button-label="بېسىپ تاللاڭ" :state="classSelectorState" v-model="formData.state_bg_class" @change="onChangeClass" @open="openClassSelector" @close="closeClassSelector" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>-->
      <b-col md="3" sm="6">
        <b-form-group id="input-group-sort" label="فىلىم تەرتىبى" label-for="sort" >
          <b-form-input style="width: 100%;" id="sort" v-model="formData.sort" type="number" placeholder="فىلىم تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-years" label="فىلىم ئىشلەنگەن ۋاقىتنى تاللاڭ" label-for="years">
          <b-form-select id="years" v-model="formData.years" :options="yearsOptions" />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-language_id" label="فىلىمنىڭ تىلىنى تاللاڭ" label-for="language_id" >
          <b-form-select id="language_id" v-model="formData.language_id" :options="languageOptions" value-field="id" text-field="title" />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-region_id" label="فىلىمنىڭ تەۋە رايونىنى تاللاڭ" label-for="region_id" >
          <b-form-select id="region_id" v-model="formData.region_id" :options="regionOptions" value-field="id" text-field="title" />
        </b-form-group>
      </b-col>
      <b-col md="2" sm="6">
        <b-form-group id="input-group-flag" label="فىلىم سەھىپىسىنى تاللاڭ" label-for="flag" :state="validateState('flag_id')" aria-describedby="flag-live-feedback">
          <b-form-select id="flag" v-model="formData.flag_id" :options="flagOptions" value-field="id" text-field="title" />
          <b-form-invalid-feedback id="flag-live-feedback">
            فىلىم تەۋە سەھىپىنى تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="4" sm="6">
        <b-form-group
          id="input-group-categories"
          :state="validateState('categories')"
          aria-describedby="categories-live-feedback"
          label="فىلىم تەۋە تۈرلەر"
          label-for="categories"
        >
          <multi-select
            placeholder="تۆۋەندىكى تۈرلەردىن تاللىسىڭىز بولىدۇ ."
            select-label="قايتۇرۇش كونۇپكىسىنى بېسىپ تاللاڭ"
            selected-label="تاللانغان"
            deselect-label="قايتۇرۇش كونۇپكىسىنى بېسىپ ئۆچۈرۈڭ"
            tag-position="bottom"
            :searchable="false"
            label="title"
            track-by="title"
            :multiple="true"
            v-model="formData.categories"
            :options="searchCategoryList" />
          <b-form-invalid-feedback id="categories-live-feedback">
            فىلىم تەۋە تۈرلەرنى تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="! storyMode">
      <b-col >
        <b-form-group id="input-group-description" label="فىلىم قىسقىچە چۈشەندۈرۈلىشى" label-for="description" >
          <b-form-textarea id="description" v-model="formData.description" type="text" placeholder="فىلىم  قىسقىچە چۈشەندۈرىلىشىنى كىرگۈزۈڭ" />
        </b-form-group>
      </b-col>
    </b-row>
    <template v-if="storyMode">
    <b-row>
      <b-col cols="12">
        <b-form-group label="ھېكايە مەزمۇنى" label-for="storyContent" tooltip :state="validateState('content', 'story')" aria-describedby="content-live-feedback">
          <editor id="storyContent" v-model="formData.story.content" />
          <b-form-invalid-feedback id="content-live-feedback">ھېكايە مەزمۇنىنى كىرگۈزۈڭ</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="3">
        <b-form-group label="ھېكايىسى سۆزلەنگەن فىلىمنى تاللاڭ" >
          <b-input-group>
            <b-form-input :state="validateState('target_id', 'story')" aria-describedby="target-live-feedback" v-model="currentTitle" placeholder="مەزكۇر ھېكايىدىكى فىلىمنى ئىزدەپ تاللاڭ" :readonly="true" />
            <b-input-group-append>
              <b-button class="d-flex justify-content-center align-content-center" @click="onClickOpenSearchFilmModal"> تاللاڭ </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback id="target-live-feedback">مەزكۇر فىلىمنى ئامباردىن ئىزدەپ تاللاڭ</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    </template>
    <b-row>
      <b-col sm="6" md="3">
        <b-form-group :state="validateState('cover_image')" aria-describedby="cover_image-live-feedback" id="input-group-cover_image" label=" ئالبوم رەسىمى" description="تىك ھالەتتىكى رەسىمنى يوللاڭ، يەنى ئېگىزلىكى كەڭلىكىدىن چوڭ بولغان رەسىمنى تاللاپ يوللاڭ" label-for="cover_image">
          <image-uploader ref="uploader" v-model="formData.cover_image" :upload-form="{strategy: 'film_cover'}" />
          <b-form-invalid-feedback id="cover_image-live-feedback">
            فىلىم ئالبوم رەسىمىنى تاللاپ يوللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="storyMode" sm="6" md="3">
        <b-form-group :state="validateState('story_banner')" aria-describedby="story_banner-live-feedback" id="input-group-story_banner" label="پىلاكات رەسىمى" description=" كەڭلىكى ئېگىزلىكىدىن چوڭ بولغان رەسىمنى تاللاپ يوللاڭ" label-for="story_banner">
          <image-uploader ref="uploader" v-model="formData.story_banner" :upload-form="{strategy: 'story_banner'}" />
          <b-form-invalid-feedback id="story_banner-live-feedback">
            فىلىم پىلاكات رەسىمىنى تاللاپ يوللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <template v-if="!formData.is_series && ! storyMode">
      <divider title="قويۇش ئۇچۇرى" class="mb-3" />
      <episode-form ref="episode" single-mode :model-data="!formData.is_series ? formData.episode : null" />
    </template>
    <b-row>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-protocol" label="كېلىشىمگە قوشۇلىمەن" label-for="protocol">
          <b-form-checkbox id="protocol" switch size="lg" v-model="formData.protocol"/>
          <template v-slot:description>
            <p> « <a class="kt-link cursor-default" @click="onClickShowProtocol" >كېلىشىم</a> » گە قوشۇلغاندىن كىيىن داۋاملىق مەشغۇلات قىلىڭ. </p>
          </template>
        </b-form-group>
      </b-col>
      <b-col md="3" sm="6" v-if="otherSetting.platform_transfer_permission">
        <b-form-group id="input-group-is_transferable" label="سۇپىلارغا بەھىرلەمسىز ؟" description="سۇپىلار ئارا بەھىرلەش مەركىزىدە مەزكۇر كىنو كۆرۈنەمدۇ؟ يەنى باشقىلار كۆچۈرەلەمدۇ؟" label-for="is_transferable">
          <b-form-checkbox id="is_transferable" switch size="lg" v-model="formData.is_transferable"/>
        </b-form-group>
      </b-col>
    </b-row>
    <protocol-modal :state="protocolModalState" @confirm="onConfirmProtocol" @close="onCloseProtocolModal" />
    <search-film-modal v-if="storyMode" v-model="filmModalState" @changed="onChangeFilm" :selected="formData.story.film_id" />
  </div>
</template>

<script>
  import filmMixin from "@m@/film.mixin";
  import languageMixin from "@m@/language.mixin";
  import categoryMixin from "@m@/category.mixin";
  import flagMixin from "@m@/flag.mixin";
  import regionMixin from "@m@/region.mixin";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import { validationMixin } from "vuelidate";
  import {required, requiredIf, maxLength } from "vuelidate/lib/validators";
  import BgClassSelector from "../modals/BgClassSelector";
  import "vue-multiselect/dist/vue-multiselect.min.css";
  import MultiSelect from 'vue-multiselect';
  import ImageUploader from "../../partials/layout/ImageUploader";
  import ProtocolModal from "../protocol/ProtocolModal";
  import EpisodeForm from "@v@/components/forms/EpisodeForm";
  import Divider from "@v@/partials/widgets/Divider";
  import { mapGetters } from "vuex";
  import searchFilmModal from "@v@/components/modals/searchFilmModal";
  import Editor from "@v@/partials/content/Editor";
  export default {
    name: "FilmForm",
    mixins: [ filmMixin, validationMixin, validateStateMixin, languageMixin, sweetAlertMixin, categoryMixin, regionMixin, flagMixin ],
    props: {
      action: {
        type: String,
        default: 'create'
      },
      modelId: {
        type: [String, Number],
      },
    },
    components: { BgClassSelector, MultiSelect, ImageUploader, ProtocolModal, EpisodeForm, Divider, searchFilmModal, Editor },
    validations(){
      let validates = {
        title:          { required },
        cover_image:    { required },
        categories:     { required },
        flag_id:        { required },
        sub_title:      { maxLength: maxLength(19) }
      };
      if ( this.formData.content_model === 'story') {
        validates['story'] = {
          target_id:      { required },
          content:      { required },
        };
        validates['story_banner'] = { required };
        delete validates.platform_ratio;
      }
      return {
        formData: validates
      }
    },
    computed: {
      ...mapGetters(['otherSetting']),
      yearsOptions(){
        let now = new Date();
        let nowYear = now.getFullYear();
        let yearFor2010 = new Date(2010, 1, 1, 0, 0, 0);
        let oldYear = yearFor2010.getFullYear();
        let years = nowYear - oldYear;
        let t = new Array(years + 1).join(',').split(',');
        let y = [];
        for ( let i in t ){
          y.push({
            value: oldYear + parseInt(i), text: `${oldYear + parseInt(i)} - يىل `
          })
        }
        y.push({ value: null, text: 'يىلىنى تاللاڭ'});
        y.unshift({value: 0, text: '2010- يىلدىن بۇرۇن'});
        return y.reverse();
      },
      handleSubTitle(){
        return this.formData.sub_title.length <= 19;
      },
      regionOptions(){
        let regions = Object.assign([], this.searchRegionList);
        regions.unshift({ id: null, title: 'رايوننى تاللاڭ' });
        return regions;
      },
      languageOptions(){
        let languages = Object.assign([], this.searchLanguageList);
        languages.unshift({ id: null, title: 'تىلىنى تاللاڭ' });
        return languages
      },
      flagOptions(){
        let flags = Object.assign([], this.searchFlagList);
        flags.unshift({ id: null, title: 'سەھىپە تاللاڭ' });
        return flags
      },
      storyMode(){
        return this.formData.content_model === 'story';
      },
      subTitleLength(){
        if( this.formData.sub_title )
          return 19 - this.formData.sub_title.length;
        return 19;
      },
    },
    created() {
      this.searchCategory();
      this.searchFlag();
      this.searchLanguage();
      this.searchRegion();
      if (this.action === 'edit'){
        this.getFilm(this.modelId)
          .then(({ data: film })=>{
            this.formData = film;
            if ( film.content_model === 'story' ){
              let { title } = film.story.target;
              this.updateCurrentTitle(title);
            }
            /*if ( ! this.formData.is_series ){
              this.episodeForm = this.$refs['episode'];
            }*/
          })
      }
    },
    mounted() {},
    data(){
      return {
        formData: {
          title: '',
          sub_title: '',
          title_color: '',
          state_content: '',
          state_bg_class: '',
          score: 6.0,
          cover_image: '',
          description: '',
          platform_ratio: 0,
          is_series: true,
          is_show: true,
          is_hidden: false,
          in_home: true,
          is_recommend: false,
          is_transferable: true,
          sort: null,
          years: null,
          language_id: null,
          region_id: null,
          flag_id: null,
          categories: [],
          episode: null,
          protocol: false,
          content_model: 'episode',
          story: {}
        },
        contentModel: [
          { text: 'فىلىم', value: 'episode' },
          { text: 'فىلىم ھېكايىسى', value: 'story' },
        ],
        protocolModalState: false,
        classSelectorState: false,
        currentTitle: '',
        filmModalState: false,
        episodeForm: {}
      }
    },
    methods: {
      handleEpisodeFormData(){
        if ( !this.formData.is_series && !this.storyMode ){
          this.episodeForm = this.$refs['episode'];
          this.formData.episode = this.$refs['episode'].formData;
        }
      },
      onSubmit(){
        this.handleEpisodeFormData();
        this.$v.formData.$touch();
        if ( !this.storyMode && !this.formData.is_series ){
          this.episodeForm.$v.formData.$touch();
        }
        if (this.$v.formData.$anyError) {
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ'});
        }else if( !this.storyMode && this.formData.is_series === false && this.episodeForm.$v.formData.$anyError ){
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ'});
        }else if ( ! this.formData.protocol ){
          return this.warning({message: 'كېلىشىمگە قوشۇلىشىڭىز كېرەك!'});
        }
        if (this.action === 'create'){
          this.storeFilm(this.formData);
        }else if ( this.action === 'edit' ){
          this.updateFilm(this.formData);
        }
      },
      onCloseProtocolModal(){
        this.protocolModalState = ! this.protocolModalState;
      },
      onClickShowProtocol(){
        this.protocolModalState = true;
      },
      onConfirmProtocol(){
        this.formData.protocol = true;
      },
      openClassSelector(){
        this.classSelectorState = true;
      },
      closeClassSelector(){
        this.classSelectorState = false;
      },
      onChangeClass(e){
        let { name } = e;
        this.formData.state_bg_class = name;
        this.closeClassSelector();
      },
      handleTitle(value){
        if ( value.length > 0 && !this.formData.sub_title ){
           this.formData.sub_title = value.substring(0, 19);
        }
        return value;
      },
      invalidSubTitleFeedback(){
        if ( this.formData.sub_title.length > 19 ){
          return 'قىسقا ماۋزو ئۇزۇنلىقى 19 ھەرىپتىن ئېشىپ كەتمىسۇن';
        }
      },
      onChangeContentModel(e){
        if ( e === 'story' ){
          this.formData.story = {
            target_id: null,
            content: ''
          };
          this.formData.is_series = null;
          this.formData.story_banner = null;
        }else if ( e === 'episode' ) {
          this.formData.story = {};
          this.formData.story_banner = null;
          this.formData.is_series = true;
        }
      },
      onClickOpenSearchFilmModal(){
        this.toggleSomeModal();
      },
      onChangeFilm(e){
        let { film } = e;
        this.updateCurrentTitle(film.title);
        this.updateSomeFormData('target_id', film.id);
        this.$nextTick(()=> {
          this.toggleSomeModal();
        });
      },
      toggleSomeModal(toggleType = 'filmModalState'){
        this[toggleType] = ! this[toggleType];
      },
      updateCurrentTitle(currentTitle = null){
        this.currentTitle = currentTitle;
      },
      updateSomeFormData(key = 'target_id', val = null, parent = 'story'){
        if ( parent )
          this.formData[parent][key] = val;
        else
          this.formData[key] = val;
      },
      onChangeSeries(e){
        if ( ! e )
          this.episodeForm = this.$refs['episode'];
        else
          this.episodeForm = {};
      }
    }
  }
</script>
