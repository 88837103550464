<template>
  <div class="form-image-uploader-container">
    <div class="row">
      <div class="col-md-3">
        <b-form-group
          :id="`input-group-${id}`"
          :label="label"
          :label-for="id"
          :state="state"
          :aria-describedby="`${id}live-feedback`"
          :description="description"
        >
          <div class="wrapper">
            <div class="row">
              <div class="col-md-12">
                <b-img-lazy style="min-width: 120px" fluid @click="showUploader" thumbnail :src="previewUrl ? previewUrl : 'assets/media/icons/svg/Files/Pictures1.svg'" blank-src="/assets/media/misc/loading.svg" />
              </div>
              <div class="col-md-12 my-2">
                <b-input-group>
                  <b-form-input  v-model="imageUrl" :state="state" :aria-describedby="`${id}live-feedback`" readonly :placeholder="placeholder"></b-form-input>
                  <b-input-group-append>
                    <b-button @click="showUploader" size="sm" variant="primary">تاللاش</b-button>
                  </b-input-group-append>
                </b-input-group>
<!--                <b-form-input :state="state" :aria-describedby="`${id}live-feedback`" @click="showUploader" v-model="imageUrl" readonly :placeholder="placeholder"></b-form-input>-->
                <b-form-invalid-feedback :id="`${id}live-feedback`">
                  مۇناسىۋەتلىك رەسىمنى يوللاڭ
                </b-form-invalid-feedback>
              </div>
              <div class="col-md-12 my-2">
                <p class="text-muted">
                  <small>{{ sizeTips }}</small>
                </p>
              </div>
            </div>
          </div>
        </b-form-group>
      </div>
    </div>
    <crop-uploader
      lang-type="ug"
      :field="field"
      v-model="show"
      :url="server"
      :params="params"
      :headers="headers"
      img-format="jpg"
      :height="height"
      :width="width"
      :no-circle="noCircle"
      :no-rotate="noRotate"
      :no-square="noSquare"
      @crop-upload-success="onUploadSuccess"
    />
  </div>
</template>

<script>
import cropUploader from "vue-image-crop-upload";
import { getToken } from "@c@/jwt.service";
export default {
  name: "FormImageUploader",
  props: {
    field: {
      type: String,
      default: "file"
    },
    id: {
      type: String,
      default: "image"
    },
    label: {
      type: String,
      default: "رەسىم تاللاڭ"
    },
    description: String,
    placeholder: {
      type: String,
      default: "رەسىم تاللاپ يوللاڭ"
    },
    imageUrl: String,
    previewUrl: String,
    height: {
      type: Number,
      default: 80
    },
    width: {
      type: Number,
      default: 80
    },
    uploadSuccess: Function,
    state: {
      type: Boolean,
      default: true
    },
    noCircle: Boolean,
    noSquare: Boolean,
    noRotate: {
      type: Boolean,
      default: true
    },
    uploadForm: {
      type: Object,
      default: ()=> { return {} }
    }
  },
  components: { cropUploader },
  computed: {
    imgSrc: {
      get() {
        if ( this.imageUrl )
          return `${process.env.VUE_APP_API_BASE_URL}/storage/${this.imageUrl}`;
        return '';
      }
    },
    sizeTips(){
      let width = this.width || 0;
      let height = this.height || 0;
      return `رەسىمنىڭ كەڭلىكى: ${ width } پېكسىل، ئېگىزلىكى ${ height } پېكسىل بولسۇن.`;
    },
    params(){
      return Object.assign({
        name: this.name
      }, this.uploadForm);
    },
  },
  data(){
    return {
      server: `${process.env.VUE_APP_API_URL}/v3/manager/image-upload`,
      show: false,
      /*params: {
        name: this.name
      },*/
      headers: {
        Authorization: `Bearer ${ getToken() }`
      },
    };
  },
  methods: {
    showUploader(){
      this.show = ! this.show;
    },
    onUploadSuccess(response){
      let id = this.id;
      this.uploadSuccess(response, id);
    }
  }
}
</script>

<style scoped>
  .wrapper {
    display: flex;
    flex-direction: column;
  }
</style>
