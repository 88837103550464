<template>
  <b-overlay :show="filmBusy">
    <error :errors="filmError" />
    <portlet title="يېڭىدىن فىلىم قوشۇش">
      <template v-slot:body>
        <film-form
          ref="film"
          @storing="onStoring"
          @stored="onStored"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button class="ml-2" variant="danger">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import FilmForm from "@v@/components/forms/FilmForm";
  import Error from "@v@/partials/widgets/Error";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  import { mapGetters } from 'vuex';
  export default {
    name: "index",
    components: { Portlet, FilmForm, Error },
    mixins: [ formBusyMixin ],
    computed: {
      ...mapGetters(['filmError'])
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'فىلىم باشقۇرۇش', route: {name: 'film.home'}});
      BreadCrumbs.addBreadCrumb({title: 'يېڭى فىلىم قوشۇش'});
      if ( this.$refs.hasOwnProperty('film') ){
        this.form = this.$refs['film'];
      }
    },
    data(){
      return {
        filmBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onStoring(){
        this.showBusy('filmBusy');
        debugConsole({prefix: 'film', message: `onStored`});
      },
      onStored(state){
        this.hideBusy('filmBusy');
        debugConsole({prefix: 'film', message: `onStored: ${state}`});
        this.$router.replace({name: 'film.home'});
      }
    }
  }
</script>

<style scoped>

</style>
