<template>
  <div class="film-show">
    <b-overlay :show="formBusy">
    <portlet fluidHeight>
      <template v-slot:body>
        <div class="kt-widget kt-widget--user-profile-3">
          <div class="kt-widget__top">
            <div class="kt-widget__media">
              <b-avatar :src="preview" text="!" size="100px" rounded="" />
            </div>
            <div class="kt-widget__content">
              <div class="kt-widget__head">
                <div class="kt-widget__user">
                  <a href="javascript:;" class="kt-widget__username">{{ title }}</a>
                  <span class="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success cursor-default mx-1 text-nowrap">{{ filmDetail.film && filmDetail.film.is_series ? 'كۆپ قىسىملىق' : 'ھېكايە فىلىم'}}</span>
                </div>
              </div>
              <div class="kt-widget__info">
                <div class="kt-widget__desc">
                  <a href="javascript:;" class="cursor-default" v-b-tooltip title="دوۋبەن نۇمۇرى"><i class="fa fa-star"></i> {{ score }} نۇمۇر </a>
                </div>
              </div>
            </div>
          </div>
          <div class="kt-widget__bottom"></div>
          <b-row class="row-no-padding row-col-separator-lg">
            <b-col sm="12" md="3">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">سېتىلغان زاكاز</h4>
                    <span class="kt-widget24__desc">ھازىرغىچە سېتىلغان زاكاز</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">{{ total_order }} دانە</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_order_progress" variant="primary"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ئۇمۇمىي زاكاز</span>
                  <span class="kt-widget24__number">{{ total_order_no_times }} دانە</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="3">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">زاكاز سوممىسى</h4>
                    <span class="kt-widget24__desc">ھازىرغىچە سېتىلغان سومما</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">{{ total_sum }} يۈەن</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_sum_progress" variant="success"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ئۇمۇمىي سومما</span>
                  <span class="kt-widget24__number">{{ total_sum_no_times }} يۈەن</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="3">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">مەنبەلىك زاكاز</h4>
                    <span class="kt-widget24__desc">مەنبەلىك ئەزالىق زاكازلىرى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">{{ total_source_order }} دانە</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_source_order_progress" variant="primary"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ئۇمۇمىي مەنبەلىك ئەزالىق</span>
                  <span class="kt-widget24__number">{{ total_source_order_no_times }} دانە</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="3">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">ئەزالىق كىرىمى</h4>
                    <span class="kt-widget24__desc">نۆۋەتتىكى ئەزالىق كىرىمى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">{{ total_source_order_sum }} يۈەن</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_source_order_sum_progress" variant="success"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ئۇمۇمىي ئەزالىق كىرىمى</span>
                  <span class="kt-widget24__number">{{ total_source_order_sum_no_times }} يۈەن</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
      <template slot="foot">
        <div class="footer-area">
          <b-row class="align-items-center">
            <b-col>
              <div class="kt-widget kt-widget--user-profile-3">
                <div class="kt-widget__top">
                  <div class="kt-widget__content pt-0">
                    <div class="kt-widget__subhead py-0">
                      <a href="javascript:;" class="cursor-default" v-b-tooltip title="فىلىم تۈرى"><i class="fa fa-layer-group"></i>{{ categories.map(item=> item.title).join('، ')}}</a>
                      <a href="javascript:;" class="cursor-default" v-b-tooltip title="تىل"><i class="fa fa-language"></i> {{ language }} </a>
                      <a href="javascript:;" class="cursor-default" v-b-tooltip title="تەۋە رايون"><i class="fa fa-globe-asia"></i> {{ region }} </a>
                      <a href="javascript:;" v-if="click_times" class="cursor-default" v-b-tooltip title="كۆرۈلۈش قېتىم سانى"><i class="fa fa-eye"></i> {{ click_times }} </a>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="d-none d-sm-flex">
              <b-input-group class="d-none d-sm-flex">
                <datetime placeholder="باشلىنىش ۋاقتىنى تاللاڭ" id="start_time" v-model="formData.start_time" class="form-control" input-class="w-100 h-100 border-0" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
                <b-input-group-prepend is-text><i class="fa fa-long-arrow-alt-left"></i></b-input-group-prepend>
                <datetime placeholder="ئاياقلىشىش ۋاقتىنى تاللاڭ" id="end_time" v-model="formData.end_time" class="form-control" input-class="w-100 h-100 border-0" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
                <b-input-group-append>
                  <b-button size="sm" :disabled="!formData.start_time || !formData.end_time" @click="onChangeTime" text="Button" variant="success">يېڭىلاش</b-button>
                  <b-button size="sm" v-if="formData.start_time && formData.end_time" @click="onClearTime" text="Button" variant="info">تازلاش</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
      </template>
    </portlet>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-center">
        <b-button variant="info" :to="{name: 'film.home'}"><i class="fa fa-long-arrow-alt-right"></i> قايتىش </b-button>
      </b-col>
    </b-row>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import paginate from "@v@/components/paginate";
  import NoResult from "@v@/partials/content/NoResult";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import sweetMixin from "@m@/common/sweetAlert.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import filmMixin from "@m@/film.mixin";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";

  export default {
    name: "index",
    components: { Portlet, Datetime, paginate, NoResult },
    mixins: [ sweetMixin, formBusyMixin, filmMixin ],
    computed: {
      filmId(){
        let { id } = this.$route.params;
        return id;
      },
      title(){
        return this.getSafeAttribute('title', 'نامەلۇم');
      },
      click_times(){
        return this.getSafeAttribute('click_times', 0);
      },
      score(){
        return this.getSafeAttribute('score', 0);
      },
      preview(){
        return this.getSafeAttribute('preview');
      },
      categories(){
        return this.getSafeAttribute('categories', []);
      },
      language(){
        return this.getSafeAttribute('title', 'ئۆز تىل', 'language');
      },
      region(){
        return this.getSafeAttribute('title', 'نامەلۇم', 'region');
      },
      total_order_progress(){
        let totalOrder = this.getSafeOrderAttribute('total_order');
        let totalOrderNoTimes = this.getSafeOrderAttribute('total_order_no_times');
        return (totalOrder / totalOrderNoTimes) * 100;
      },
      total_order(){
        return this.getSafeOrderAttribute('total_order');
      },
      total_order_no_times(){
        return this.getSafeOrderAttribute('total_order_no_times');
      },
      total_sum_progress(){
        let totalOrder = this.getSafeOrderAttribute('total_sum');
        let totalOrderNoTimes = this.getSafeOrderAttribute('total_sum_no_times');
        return (totalOrder / totalOrderNoTimes) * 100;
      },
      total_sum(){
        return this.getSafeOrderAttribute('total_sum');
      },
      total_sum_no_times(){
        return this.getSafeOrderAttribute('total_sum_no_times');
      },
      total_source_order_progress(){
        let totalOrder = this.getSafeOrderAttribute('total_source_order');
        let totalOrderNoTimes = this.getSafeOrderAttribute('total_source_order_no_times');
        return (totalOrder / totalOrderNoTimes) * 100;
      },
      total_source_order(){
        return this.getSafeOrderAttribute('total_source_order');
      },
      total_source_order_no_times(){
        return this.getSafeOrderAttribute('total_source_order_no_times');
      },

      total_source_order_sum_progress(){
        let totalOrder = this.getSafeOrderAttribute('total_source_order_sum');
        let totalOrderNoTimes = this.getSafeOrderAttribute('total_source_order_sum_no_times');
        return (totalOrder / totalOrderNoTimes) * 100;
      },
      total_source_order_sum(){
        return this.getSafeOrderAttribute('total_source_order_sum');
      },
      total_source_order_sum_no_times(){
        return this.getSafeOrderAttribute('total_source_order_sum_no_times');
      },
    },
    created() {
      this.getFilmDetail(this.filmId)
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'فىلىم باشقۇرۇش', route: {name: 'film.home'}});
      BreadCrumbs.addBreadCrumb({ title: "فىلىم تەپسىلاتى" });
    },
    data(){
      return {
        formData: {
          start_time: null,
          end_time: null,
        },
        formBusy: false
      };
    },
    methods: {
      onChangePage(page = 1){
        // this.getUserWalletLogs(this.userId, page, this.formData);
      },
      onChangeTime(){
        this.getFilmDetail(this.filmId, this.formData)
        // this.getUserWalletLogs(this.userId, 1, this.formData);
      },
      onClearTime(){
        this.formData.start_time = null;
        this.formData.end_time = null;
        this.getFilmDetail(this.filmId)
      },
      getSafeAttribute(key, def_str = '', parent = null){
        if ( this.filmDetail.hasOwnProperty('film') ){
          if ( parent ){
            if ( this.filmDetail.film.hasOwnProperty(parent) && this.filmDetail.film.parent ){
              if ( this.filmDetail.film[parent].hasOwnProperty(key) ){
                return this.filmDetail.film[parent][key];
              }else {
                return def_str;
              }
            }else {
              return def_str;
            }
          }
          if ( this.filmDetail.film.hasOwnProperty(key) ){
            return this.filmDetail.film[key];
          }else {
            return def_str;
          }
        }
        return def_str;
      },
      getSafeOrderAttribute(key, def_str = 0){
        if ( this.filmDetail.hasOwnProperty(key) ){
          return this.filmDetail[key];
        }
        return def_str;
      }
    }
  }
</script>


<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }

  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
    padding-top: 1rem;
  }
  @media (max-width: 768px) {
    .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
      padding-right: 1rem;
      padding-top: 0;
    }

    .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead {
      padding: 0.5rem 0;
    }
  }

  .kt-widget-corner-marker, .kt-widget-corner-marker-wrapper {
    bottom: 0;
    right: 0;
    height: 100px;
    width: 100px;
  }

  .kt-widget-corner-marker-wrapper {
    bottom: -25px;
    right: -25px;
  }

  .kt-widget-corner-marker-wrapper i {
    font-size: 100px;
  }

  .kt-svg-icon {
    height: 100px;
    width: 100px;
  }

  .kt-widget-corner-marker-wrapper>* {
    opacity: .5;
  }


</style>
