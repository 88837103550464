<template>
  <div class="bg-selector">
    <div @click="onClickShow" class="opener px-2"> {{ buttonLabel }}</div>
    <b-modal header-close-content="" body-class="bg-ghost-white" @hidden="hiddenModal" v-model="modalState" size="lg" :id="id" hide-footer :title="title">
      <b-row class="pb-5">
        <b-col md="2" sm="3" class="pb-3" v-for="item in colorList" :key="item.name">
          <div class="item p-3 shadow-warp cursor-pointer" @click="onChooseItem(item)" :class="item.name">
            {{ testContent }}
            <b-badge v-if="item.name === value" variant="primary" pill class="item-badge"><i class="fa fa-check"></i></b-badge>
          </div>
        </b-col>
      </b-row>
      <b-row class="pb-5">
        <b-col md="2" sm="3" class="pb-3" v-if="12 > i" v-for="(item,i) in colorList" :key="`${item.name}_light`">
          <div class="item p-3 light shadow-warp cursor-pointer" @click="onChooseItem(item, 'light')" :class="item.name">
            {{ testContent }}
            <b-badge v-if="`${item.name} light` === value" variant="primary" pill class="item-badge"><i class="fa fa-check"></i></b-badge>
          </div>
        </b-col>
      </b-row>
      <b-row >
        <b-col md="2" sm="3" class="pb-3" v-for="item in gradualColorList" :key="item.name">
          <div class="item p-3 shadow-warp cursor-pointer" @click="onChooseItem(item)" :class="item.name">
            {{ testContent }}
            <b-badge v-if="item.name === value" variant="primary" pill class="item-badge"><i class="fa fa-check"></i></b-badge>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: "BgClassSelector",
    props: {
      state: {
        type: Boolean,
        default: false
      },
      id: {
        type: String,
        default: () => 'class-selector'
      },
      value: {
        type: String,
        default: ""
      },
      buttonLabel: {
        type: String,
        default: () => 'تاللاڭ'
      },
      title: {
        type: String,
        default: () => 'ئارقا رەڭ تاللاش'
      },
      testContent: {
        type: String,
        default: ()=> 'كىنو - فىلىم'
      }
    },
    computed: {
      modalState: {
        get() {
          return this.state;
        },
        set(v) {
          return v;
        }
      }
    },
    data() {
      return {
        colorList: [
          {
            title: '嫣红',
            name: 'bg-red',
            color: '#e54d42'
          },
          {
            title: '桔橙',
            name: 'bg-orange',
            color: '#f37b1d'
          },
          {
            title: '明黄',
            name: 'bg-yellow',
            color: '#fbbd08'
          },
          {
            title: '橄榄',
            name: 'bg-olive',
            color: '#8dc63f'
          },
          {
            title: '森绿',
            name: 'bg-green',
            color: '#39b54a'
          },
          {
            title: '天青',
            name: 'bg-cyan',
            color: '#1cbbb4'
          },
          {
            title: '海蓝',
            name: 'bg-blue',
            color: '#0081ff'
          },
          {
            title: '姹紫',
            name: 'bg-purple',
            color: '#6739b6'
          },
          {
            title: '木槿',
            name: 'bg-mauve',
            color: '#9c26b0'
          },
          {
            title: '桃粉',
            name: 'bg-pink',
            color: '#e03997'
          },
          {
            title: '棕褐',
            name: 'bg-brown',
            color: '#a5673f'
          },
          {
            title: '玄灰',
            name: 'bg-grey',
            color: '#8799a3'
          },
          {
            title: '草灰',
            name: 'bg-gray',
            color: '#aaaaaa'
          },
          {
            title: '墨黑',
            name: 'bg-black',
            color: '#333333'
          },
          {
            title: '雅白',
            name: 'bg-white',
            color: '#ffffff'
          },
        ],
        gradualColorList: [
          {
            title: '魅红',
            name: 'bg-gradual-red',
            color: '#f43f3b|#ec008c'
          },
          {
            title: '鎏金',
            name: 'bg-gradual-orange',
            color: '#ff9700,#ed1c24'
          },
          {
            title: '翠柳',
            name: 'bg-gradual-green',
            color: '#ff9700,#ed1c24'
          },
          {
            title: '靛青',
            name: 'bg-gradual-blue',
            color: '#0081ff,#1cbbb4'
          },
          {
            title: '惑紫',
            name: 'bg-gradual-purple',
            color: '#9000ff,#5e00ff'
          },
          {
            title: '霞彩',
            name: 'bg-gradual-pink',
            color: '#ec008c,#6739b6'
          },
        ]
      }
    },
    methods: {
      hiddenModal(event) {
        let {id} = this.id;
        this.$emit('close', {id});
      },
      changeModal(event) {
        let {id} = this.id;
        this.$emit("input", {id});
      },
      onClickShow() {
        let {id} = this.id;
        this.$emit('open', {id});
      },
      onChooseItem(item, suffix = null){
        let { name } = item;
        this.$emit('change', {name: `${name}${suffix ? ' ' + suffix : ''}`});
      }
    }
  }
</script>

<style >

  .cursor-pointer {
    cursor: pointer;
  }

  .bg-ghost-white {
    background-color: #f1f1f1 !important;
  }

  .bg-red {
    background-color: #e54d42 !important;
    color: #ffffff !important;
  }

  .bg-orange {
    background-color: #f37b1d !important;
    color: #ffffff !important;
  }

  .bg-yellow {
    background-color: #fbbd08 !important;
    color: #333333 !important;
  }

  .bg-olive {
    background-color: #8dc63f !important;
    color: #ffffff !important;
  }

  .bg-green {
    background-color: #39b54a !important;
    color: #ffffff !important;
  }

  .bg-cyan {
    background-color: #1cbbb4 !important;
    color: #ffffff !important;
  }

  .bg-blue {
    background-color: #0081ff !important;
    color: #ffffff !important;
  }

  .bg-purple {
    background-color: #6739b6 !important;
    color: #ffffff !important;
  }

  .bg-mauve {
    background-color: #9c26b0 !important;
    color: #ffffff !important;
  }

  .bg-pink {
    background-color: #e03997 !important;
    color: #ffffff !important;
  }

  .bg-brown {
    background-color: #a5673f !important;
    color: #ffffff !important;
  }

  .bg-grey {
    background-color: #8799a3 !important;
    color: #ffffff !important;
  }

  .bg-gray {
    background-color: #f0f0f0 !important;
    color: #333333 !important;
  }

  .bg-black {
    background-color: #333333 !important;
    color: #ffffff !important;
  }

  .bg-white {
    background-color: #ffffff !important;
    color: #666666 !important;
  }

  .bg-shadeTop {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.01)) !important;
    color: #ffffff !important;
  }

  .bg-shadeBottom {
    background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1)) !important;
    color: #ffffff !important;
  }

  .bg-red.light {
    color: #e54d42 !important;
    background-color: #fadbd9 !important;
  }

  .bg-orange.light {
    color: #f37b1d !important;
    background-color: #fde6d2 !important;
  }

  .bg-yellow.light {
    color: #fbbd08 !important;
    background-color: #fef2ce !important;
  }

  .bg-olive.light {
    color: #8dc63f !important;
    background-color: #e8f4d9 !important;
  }

  .bg-green.light {
    color: #39b54a !important;
    background-color: #d7f0db !important;
  }

  .bg-cyan.light {
    color: #1cbbb4 !important;
    background-color: #d2f1f0 !important;
  }

  .bg-blue.light {
    color: #0081ff !important;
    background-color: #cce6ff !important;
  }

  .bg-purple.light {
    color: #6739b6 !important;
    background-color: #e1d7f0 !important;
  }

  .bg-mauve.light {
    color: #9c26b0 !important;
    background-color: #ebd4ef !important;
  }

  .bg-pink.light {
    color: #e03997 !important;
    background-color: #f9d7ea !important;
  }

  .bg-brown.light {
    color: #a5673f !important;
    background-color: #ede1d9 !important;
  }

  .bg-grey.light {
    color: #8799a3 !important;
    background-color: #e7ebed !important;
  }

  .bg-gradual-red {
    background-image:  linear-gradient(45deg, #f43f3b, #ec008c) !important;
    color: #ffffff !important;
  }

  .bg-gradual-orange {
    background-image: linear-gradient(45deg, #ff9700, #ed1c24) !important;
    color: #ffffff !important;
  }

  .bg-gradual-green {
    background-image: linear-gradient(45deg, #39b54a, #8dc63f) !important;
    color: #ffffff !important;
  }

  .bg-gradual-purple {
    background-image: linear-gradient(45deg, #9000ff, #5e00ff) !important;
    color: #ffffff !important;
  }

  .bg-gradual-pink {
    background-image: linear-gradient(45deg, #ec008c, #6739b6) !important;
    color: #ffffff !important;
  }

  .bg-gradual-blue {
    background-image: linear-gradient(45deg, #0081ff, #1cbbb4) !important;
    color: #ffffff !important;
  }


  .shadow[class*='white'] {
    box-shadow: 0 1px 3px;
  }

  .shadow-lg {
    box-shadow: 0 20px 50px 0;
  }

  .shadow-warp {
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .shadow-warp:before,
  .shadow-warp:after {
    position: absolute;
    content: "";
    top: 10px;
    bottom: 15px;
    left: 10px;
    width: 50%;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
    transform: rotate(-3deg);
    z-index: -1;
  }

  .shadow-warp:after {
    right: 10px;
    left: auto;
    transform: rotate(3deg);
  }

  .shadow-blur {
    position: relative;
  }

  .shadow-blur::before {
    content: "";
    display: block;
    background: inherit;
    filter: blur(5px);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5px;
    left: 5px;
    z-index: -1;
    opacity: 0.4;
    transform-origin: 0 0;
    border-radius: inherit;
    transform: scale(1, 1);
  }

  .shadow[class*="-red"] {
    box-shadow: 3px 3px 4px rgba(204, 69, 59, 0.2);
  }

  .shadow[class*="-orange"] {
    box-shadow: 3px 3px 4px rgba(217, 109, 26, 0.2);
  }

  .shadow[class*="-yellow"] {
    box-shadow: 3px 3px 4px rgba(224, 170, 7, 0.2);
  }

  .shadow[class*="-olive"] {
    box-shadow: 3px 3px 4px rgba(124, 173, 55, 0.2);
  }

  .shadow[class*="-green"] {
    box-shadow: 3px 3px 4px rgba(48, 156, 63, 0.2);
  }

  .shadow[class*="-cyan"] {
    box-shadow: 3px 3px 4px rgba(28, 187, 180, 0.2);
  }

  .shadow[class*="-blue"] {
    box-shadow: 3px 3px 4px rgba(0, 102, 204, 0.2);
  }

  .shadow[class*="-purple"] {
    box-shadow: 3px 3px 4px rgba(88, 48, 156, 0.2);
  }

  .shadow[class*="-mauve"] {
    box-shadow: 3px 3px 4px rgba(133, 33, 150, 0.2);
  }

  .shadow[class*="-pink"] {
    box-shadow: 3px 3px 4px rgba(199, 50, 134, 0.2);
  }

  .shadow[class*="-brown"] {
    box-shadow: 3px 3px 4px rgba(140, 88, 53, 0.2);
  }

  .shadow[class*="-grey"] {
    box-shadow: 3px 3px 4px rgba(114, 130, 138, 0.2);
  }

  .shadow[class*="-gray"] {
    box-shadow: 3px 3px 4px rgba(114, 130, 138, 0.2);
  }

  .shadow[class*="-black"] {
    box-shadow: 3px 3px 4px rgba(26, 26, 26, 0.2);
  }

  .shadow[class*="-white"] {
    box-shadow: 3px 3px 4px rgba(26, 26, 26, 0.2);
  }

  .opener {
    height: 100%;
    width: 100%;
  }

  .item {
    text-align: center;
    position: relative;
  }

  .item-badge {
    position: absolute;
    top: -10px;
    right: -10px;
  }
</style>
